#chat-widget-container {
	@apply tw-pointer-events-none md:tw-pointer-events-auto;
	
	@media print {
		@apply tw-hidden;
	}
}

#chat-widget-minimized {
	@apply #{'sm:!tw-hidden'};

	&:not(.md-not-hidden) {
		@apply #{'md:!tw-hidden'};
	}
}
